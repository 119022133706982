'use client'; // https://www.blocknotejs.org/docs/advanced/nextjs - can't use blocknote in server side
import React from 'react';
import clsx from 'clsx';
import { BlockDesign, HubButtonData, ImageMeta } from '../types';
import CloudinaryImage from '../ui/cloudinary-image';
import { HubButton } from '../ui/hub-button';
import { PlainText } from '../ui/plain-text';
import { RenderBlockNote } from '../ui/render-block-note';
import { emitBlockClickEvent } from '../utils';

export interface HeroBlockProps {
  blockId: string;
  content: {
    backgroundImage?: ImageMeta | null;
    backgroundImageAltText?: string;
    buttons?: HubButtonData[];
    description?: string;
    design?: BlockDesign;
    heading?: string;
    variant?: 'angled_image' | 'straight_image';
  };
  index?: number;
  removeBottomSpacing?: boolean;
  removeTopSpacing?: boolean;
  renderButtons: (buttons: HubButtonData[]) => React.ReactNode;
  renderDescription: (textColor: string) => React.ReactNode;
}

const PLACEHOLDER_HEADING = 'Data to enrich your online business';
const PLACEHOLDER_DESCRIPTION =
  'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.';

export const AngledHeroBlock: React.ComponentType<HeroBlockProps> = ({
  blockId,
  content,
  index,
  removeBottomSpacing,
  removeTopSpacing,
  renderButtons,
  renderDescription,
}) => {
  return (
    <div
      className={clsx(
        `relative scroll-mt-[120px]`,
        removeTopSpacing && '-mt-16 md:-mt-20',
        removeBottomSpacing && '-mb-16 md:-mb-20'
      )}
      id="hero_block"
      style={
        content.design?.backgroundColor
          ? { backgroundColor: content.design.backgroundColor }
          : undefined
      }
      onClick={() => emitBlockClickEvent(blockId)}
    >
      <div className="mx-auto grid w-full max-w-screen-xl overflow-hidden lg:grid-cols-12 lg:gap-x-16">
        <div className="relative z-10 flex items-center lg:col-span-8 lg:w-full lg:max-w-2xl xl:col-span-7">
          {/* h-[101%] below & overflow-hidden in parent to cover a little visual bug on some browsers where the svg doesnt fully cover image */}
          <svg
            aria-hidden="true"
            className="absolute inset-y-0 right-0 hidden h-[101%] w-80 translate-x-1/2 fill-hubs-background lg:block"
            preserveAspectRatio="none"
            style={{ fill: content.design?.backgroundColor }}
            viewBox="0 0 100 100"
          >
            <polygon points="0,0 90,0 50,100 0,100" />
          </svg>
          <div className="z-10 flex max-w-2xl flex-col items-start gap-8 px-4 py-24 sm:px-6 lg:mx-0 lg:min-h-[640px] lg:justify-center lg:pr-0 2xl:min-h-[720px]">
            <h1 className="font-heading text-4xl font-semibold text-hubs-primary md:text-5xl">
              {content.heading ?? PLACEHOLDER_HEADING}
            </h1>
            {renderDescription(
              'text-hubs-secondary prose-headings:text-hubs-secondary prose-p:text-hubs-secondary prose-a:text-hubs-secondary prose-strong:text-hubs-secondary prose-table:text-hubs-secondary marker:text-hubs-secondary'
            )}
            {renderButtons(content.buttons ? content.buttons : [])}
          </div>
        </div>
      </div>
      <div className="overflow-hidden lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:py-0">
        {content.backgroundImage && (
          <CloudinaryImage
            alt={
              content.backgroundImageAltText ||
              content.backgroundImage.originalFilename ||
              'Background image'
            }
            className="aspect-video h-full w-full object-cover lg:aspect-auto lg:object-left"
            crop="fill"
            gravity="auto"
            height={1000}
            priority={index === 0}
            src={content.backgroundImage.cloudinaryUrl || ''}
            width={1000}
          />
        )}
      </div>
    </div>
  );
};

export const StraightHeroBlock: React.ComponentType<HeroBlockProps> = ({
  blockId,
  content,
  index,
  removeBottomSpacing,
  removeTopSpacing,
  renderButtons,
  renderDescription,
}) => {
  return (
    <div
      className={clsx(
        `relative`,
        removeTopSpacing && '-mt-16 md:-mt-20',
        removeBottomSpacing && '-mb-16 md:-mb-20'
      )}
      style={
        content.design?.backgroundColor
          ? { backgroundColor: content.design.backgroundColor }
          : undefined
      }
      onClick={() => emitBlockClickEvent(blockId)}
    >
      <div className="relative z-[1] mx-auto grid w-full max-w-screen-xl px-4 md:px-6 lg:grid-cols-12 lg:gap-x-16">
        <div
          className={clsx(
            'flex max-w-2xl flex-col gap-8 py-24 sm:pr-6 lg:col-span-6 lg:mx-0 lg:min-h-[640px] lg:justify-center lg:px-0 xl:col-span-6 2xl:min-h-[720px]',
            !content.design?.backgroundColor && 'bg-hubs-background'
          )}
        >
          <h1 className="font-heading text-4xl font-semibold text-hubs-primary sm:mt-10 md:text-5xl lg:mt-0">
            {content.heading ?? PLACEHOLDER_HEADING}
          </h1>
          {renderDescription(
            'text-hubs-primary prose-headings:text-hubs-primary prose-p:text-hubs-primary prose-a:text-hubs-primary prose-strong:text-hubs-primary prose-table:text-hubs-primary marker:text-hubs-primary'
          )}
          {renderButtons(content.buttons ? content.buttons : [])}
        </div>
      </div>
      <div
        className="relative z-0 lg:absolute lg:inset-0 lg:left-1/2 lg:-ml-8 lg:bg-transparent lg:py-0 xl:ml-0"
        style={
          content.design?.backgroundColor
            ? { backgroundColor: content.design.backgroundColor }
            : undefined
        }
      >
        {content.backgroundImage && content.backgroundImage.cloudinaryUrl && (
          <CloudinaryImage
            alt=""
            className="aspect-video h-full w-full object-cover lg:absolute lg:right-0 lg:aspect-auto lg:h-full lg:w-[90%] lg:object-cover"
            crop="fill"
            gravity="auto"
            height={1000}
            priority={index === 0 ? true : false}
            src={content.backgroundImage.cloudinaryUrl || ''}
            width={1000}
          />
        )}
      </div>
    </div>
  );
};

export const HeroBlock: React.ComponentType<HeroBlockProps> = ({
  blockId,
  content,
  index,
  removeBottomSpacing,
  removeTopSpacing,
}) => {
  function renderButtons(buttons: HubButtonData[]) {
    // this hides button parent div if no buttons, or if both buttons have empty labels
    // the flex parent of this div creates extra gap/space without this handler
    // inside HeroBlock wrapper for DRY reasons
    if (!buttons.length || buttons.every((button) => button.label === ''))
      return null;
    return (
      <div className="z-0 flex w-full flex-col items-center gap-6 md:w-auto md:flex-row">
        {buttons.map((button, index) => {
          if (!button.label) return null;
          return (
            <HubButton
              key={index}
              className="w-full justify-center md:w-auto"
              hasArrow={button.hasArrow}
              isPrimary={button.isPrimary}
              label={button.label}
              openInNewTab={button.openInNewTab}
              size="lg"
              url={button.url}
            />
          );
        })}
      </div>
    );
  }

  function renderDescription(textColor: string) {
    if (content.description) {
      if (typeof content.description === 'string') {
        return (
          <PlainText
            className={clsx('font-body text-lg leading-normal', textColor)}
            content={content.description ?? PLACEHOLDER_DESCRIPTION}
          />
        );
      }

      return (
        <RenderBlockNote
          // tailwindcss/typography's plugin prose includes a max-w- on it, so use max-w-none to override, don't remove prose.
          // https://github.com/tailwindlabs/tailwindcss-typography
          className={clsx(
            'blocknote prose max-w-none list-inside list-disc font-body text-lg leading-normal',
            textColor
          )}
          content={content.description}
        />
      );
    }
    return null;
  }

  if (content.variant === 'angled_image') {
    return (
      <AngledHeroBlock
        blockId={blockId}
        content={content}
        index={index}
        removeBottomSpacing={removeBottomSpacing}
        removeTopSpacing={removeTopSpacing}
        renderButtons={renderButtons}
        renderDescription={renderDescription}
      />
    );
  }

  return (
    <StraightHeroBlock
      blockId={blockId}
      content={content}
      index={index}
      removeBottomSpacing={removeBottomSpacing}
      removeTopSpacing={removeTopSpacing}
      renderButtons={renderButtons}
      renderDescription={renderDescription}
    />
  );
};
