import React from 'react';
import { DownloadIcon, DocumentIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import {
  GetWebinarDataQuery,
  useDownloadWebinarDocumentMutation,
} from '../../../apollo/generated';
import { Typography } from '../../../index';

interface WebinarDocumentsProps {
  canDownload: boolean;
  documents: NonNullable<
    NonNullable<GetWebinarDataQuery['webinarData']>['webinar']
  >['documents'];
}

export const WebinarDocuments: React.FC<WebinarDocumentsProps> = ({
  canDownload,
  documents,
}) => {
  if (!documents || documents.length === 0) return null;

  const sortedDocuments = [...documents].sort(
    (a, b) => (a?.position ?? 0) - (b?.position ?? 0)
  );

  return (
    <div className="mb-4">
      <div className="grid grid-cols-1 gap-x-14 gap-y-4 md:grid-cols-2">
        {sortedDocuments.map((doc) => {
          if (!doc) return null;
          return (
            <Document
              key={doc.id}
              bytes={doc.fileSize || 0}
              canDownload={canDownload}
              cloudinaryPublicId={doc.cloudinaryId || ''}
              fileType={doc.fileType || ''}
              id={doc.id}
              name={doc.fileName || ''}
            />
          );
        })}
      </div>
    </div>
  );
};

interface DocumentProps {
  bytes: number;
  canDownload: boolean;
  cloudinaryPublicId: string;
  fileType: string;
  id: string;
  name: string;
}

const Document: React.FC<DocumentProps> = ({
  bytes,
  canDownload,
  cloudinaryPublicId,
  fileType = 'raw',
  id,
  name,
}) => {
  const [downloadWebinarDocument] = useDownloadWebinarDocumentMutation();

  function getSize(bytes: number) {
    if (bytes === undefined) return '';

    if (bytes >= 1000000) {
      return `${(bytes / 1000000).toFixed(0)} MB`;
    } else if (bytes >= 1000) {
      return `${(bytes / 1000).toFixed(0)} KB`;
    } else {
      return `${bytes} B`;
    }
  }

  function getDownloadUrl(publicId: string, fileType: string) {
    const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
    const baseUrl = `https://res.cloudinary.com/${cloudName}/${fileType}/upload/`;
    return `${baseUrl}${publicId}`;
  }

  const handleDownload = async () => {
    if (!canDownload) {
      alert(
        'Please register to this webinar before downloading any documents.'
      );
      return;
    }
    await downloadWebinarDocument({ variables: { documentId: id } });
    window.open(getDownloadUrl(cloudinaryPublicId, fileType), '_blank');
  };

  return (
    <div className="box-border flex w-full items-center justify-between border-b pb-2 pt-4">
      <Link
        className="group flex cursor-pointer flex-row items-start justify-start gap-2"
        href={getDownloadUrl(cloudinaryPublicId, fileType)}
        rel="noopener noreferrer"
        target="_blank"
        onClick={(e) => {
          e.preventDefault();
          handleDownload();
        }}
      >
        <DocumentIcon className="size-6 rounded-lg bg-none stroke-[1.5] text-hubs-secondary transition-colors" />
        <div className="flex flex-col items-start gap-1">
          <Typography
            className="font-body text-base font-normal text-company-primary underline underline-offset-2"
            variant="subtitle-1"
          >
            {name || 'Unnamed document'}
          </Typography>
          {!!bytes && (
            <Typography
              className="font-body text-xs font-normal uppercase text-hubs-secondary"
              variant="subtitle-2"
            >
              {getSize(bytes)}, PDF
            </Typography>
          )}
        </div>
      </Link>
      <button className="flex cursor-pointer" onClick={handleDownload}>
        <DownloadIcon className="h-10 w-10 rounded-lg bg-none stroke-[1.5] p-2 text-hubs-secondary transition-all hover:bg-gray-100 hover:text-hubs-secondary" />
      </button>
    </div>
  );
};
