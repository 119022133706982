import { forwardRef } from 'react';
import clsx from 'clsx';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-active';

export type ButtonColor = 'black' | 'grey' | 'white' | 'company' | 'footer';

export interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  color?: ButtonColor;
  disabled?: boolean;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  startIcon?: React.ReactNode;
  style?: React.CSSProperties;
  testId?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariant;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      color = 'black',
      disabled,
      endIcon,
      fullWidth,
      onClick,
      size = 'lg',
      startIcon,
      style,
      testId,
      type = 'button',
      variant = 'primary',
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(
          'box-border flex items-center justify-center space-x-2 truncate rounded-lg font-body !leading-tight tracking-[0.2px] opacity-100 outline-none transition-all disabled:cursor-not-allowed disabled:text-text-disabled [&:not(:disabled)]:hover:opacity-80',
          size === 'lg' && 'min-h-[46px] px-4 py-2 text-base',
          size === 'md' && 'min-h-[40px] px-3 py-1 text-base',
          size === 'sm' && 'min-h-[32px] px-3 py-2 text-sm',
          size === 'xs' && 'px-2 py-1 text-sm',
          color === 'black' &&
            variant === 'primary' &&
            'border border-company-accent bg-company-accent text-company-accent-text disabled:border-hubs-dividers disabled:bg-hubs-dividers',
          color === 'company' &&
            variant === 'primary' &&
            'border border-company-primary bg-company-primary text-company-primary-text disabled:border-hubs-dividers disabled:bg-hubs-dividers',
          color === 'footer' &&
            ':border-hubs-dividers border border-footer-button-background-color bg-footer-button-background-color text-footer-button-text-color disabled:bg-hubs-dividers',
          color === 'white' &&
            variant === 'primary' &&
            'border border-hubs-dividers bg-hubs-fixed-white text-hubs-fixed-dark disabled:bg-hubs-dividers',
          color === 'black' &&
            variant === 'secondary' &&
            'border border-company-primary text-company-primary disabled:border-hubs-dividers',
          color === 'white' &&
            variant === 'secondary' &&
            'border border-white text-white disabled:border-hubs-dividers',
          color === 'grey' &&
            variant === 'tertiary' &&
            'bg-gray-100 text-hubs-fixed-dark active:bg-hubs-dividers',
          variant === 'tertiary-active' && 'text-hubs-fixed-dark underline',
          fullWidth && 'w-full',
          '',
          className
        )}
        data-test={testId}
        disabled={disabled}
        style={style}
        type={type}
        onClick={onClick}
      >
        {startIcon}
        {children ? (
          <span className="flex items-center gap-2">{children}</span>
        ) : null}
        {endIcon}
      </button>
    );
  }
);

export default Button;
