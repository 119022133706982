'use client';

import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { type Block } from '@blocknote/core';
import {
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from '@heroicons/react-v2/24/outline';
import { CheckIcon } from '@heroicons/react/solid';
import 'next-cloudinary/dist/cld-video-player.css';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { CldVideoPlayer } from 'next-cloudinary';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import {
  GetWebinarDataQuery,
  useRegisterForWebinarMutation,
  useViewWebinarRecordingMutation,
  useCreateOrUpdateWebinarViewMutation,
  CurrentInvestorUserQuery,
} from '../../../apollo/generated';
import {
  BlockHeading,
  HubButton,
  Typography,
  Badge,
  RenderBlockNote,
  WebinarDocuments,
  formatVideoUrl,
} from '../../../index';
import { useAlert } from '../contexts/alert-context';
import { setReturnToCookie } from '../utils';
import { WebinarBackgroundContainer } from './webinar-background-container';
import { WebinarQuestions } from './webinar-questions';
import {
  getTimeRemaining,
  useTimeRemaining,
  useFullscreen,
  formatDuration,
  formatTimeRemaining,
  getLocalDate,
} from './webinar-util';

dayjs.extend(utc);
dayjs.extend(timezone);

interface WebinarViewerProps {
  attendee?: NonNullable<
    NonNullable<GetWebinarDataQuery['webinarData']>['attendee']
  > | null;
  client?: ApolloClient<NormalizedCacheObject>;
  currentUser?: CurrentInvestorUserQuery['currentInvestorUser'];
  name?: string;
  roomCodeOverride?: string | undefined;
  webinar: NonNullable<
    NonNullable<GetWebinarDataQuery['webinarData']>['webinar']
  >;
}

const formatWebinarTime = (
  date: string | null | undefined,
  timezone: string | null | undefined,
  format: string
) => {
  return getLocalDate(date, timezone).format(format);
};

const getWebinarDuration = (
  startTime: string | null | undefined,
  endTime: string | null | undefined,
  timezone: string | null | undefined
) => {
  const start = getLocalDate(startTime, timezone);
  const end = getLocalDate(endTime, timezone);
  return {
    hours: end.diff(start, 'hour'),
    minutes: end.diff(start, 'minute') % 60,
  };
};

export const WebinarViewer: React.FC<WebinarViewerProps> = ({
  attendee,
  client,
  currentUser,
  roomCodeOverride,
  webinar,
}) => {
  const isLoggedIn = !!currentUser;
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const { isFullscreen, toggleFullscreen } = useFullscreen(videoContainerRef);
  const videoRef = useRef<HTMLVideoElement>(null);

  const timeRemaining = useTimeRemaining(
    webinar.startTime || '',
    webinar.timezone
  );

  const [registerMutation] = useRegisterForWebinarMutation({
    refetchQueries: ['GetWebinarData'],
  });
  const [viewRecordingMutation] = useViewWebinarRecordingMutation({
    refetchQueries: ['GetWebinarData'],
  });
  const { formatAndShowError, showAlert } = useAlert();

  const [cookies] = useCookies(['_hermes_web_visitor']);
  const [createOrUpdateWebinarView] = useCreateOrUpdateWebinarViewMutation();

  const [isPlaying, setIsPlaying] = useState(false);
  const [watchTimeSeconds, setWatchTimeSeconds] = useState(0);
  const [hasClickedVideoOverlay, setHasClickedVideoOverlay] = useState(false);

  useEffect(() => {
    if (!isPlaying) return;

    const interval = setInterval(async () => {
      try {
        await createOrUpdateWebinarView({
          variables: {
            attendeeId: attendee?.id,
            visitorCookieId: cookies._hermes_web_visitor,
            watchTimeSeconds: watchTimeSeconds + 10,
            webinarId: webinar.id,
          },
        });
        setWatchTimeSeconds((prev) => prev + 10);
      } catch (error) {
        formatAndShowError(error);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [
    isPlaying,
    watchTimeSeconds,
    webinar.id,
    cookies._hermes_web_visitor,
    attendee?.id,
    createOrUpdateWebinarView,
    formatAndShowError,
  ]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);
    const handlePlay = () => setIsPlaying(true);

    video.addEventListener('pause', handlePause);
    video.addEventListener('ended', handleEnded);
    video.addEventListener('play', handlePlay);

    return () => {
      video.removeEventListener('pause', handlePause);
      video.removeEventListener('ended', handleEnded);
      video.removeEventListener('play', handlePlay);
    };
  }, []);

  const handleRegister = useCallback(async () => {
    try {
      if (webinar.state === 'completed' && webinar.publishedRecordingUrl) {
        await viewRecordingMutation({ variables: { webinarId: webinar.id } });
        showAlert({
          description: 'Recording access granted',
          variant: 'success',
        });
        setTimeout(() => {
          videoRef.current?.play();
        }, 100);
      } else {
        await registerMutation({ variables: { webinarId: webinar.id } });
        showAlert({
          description: 'Successfully registered for the webinar',
          variant: 'success',
        });
      }
    } catch (error) {
      formatAndShowError(error);
    }
  }, [
    registerMutation,
    viewRecordingMutation,
    webinar.id,
    webinar.state,
    webinar.publishedRecordingUrl,
    showAlert,
    formatAndShowError,
  ]);

  const handleWatchRecording = useCallback(async () => {
    try {
      await createOrUpdateWebinarView({
        variables: {
          attendeeId: attendee?.id,
          isMobile: /Mobile|Android|iPhone|iPad|iPod/i.test(
            navigator.userAgent
          ),
          userAgent: navigator.userAgent,
          visitorCookieId: cookies._hermes_web_visitor,
          watchTimeSeconds: 0,
          webinarId: webinar.id,
        },
      });

      setWatchTimeSeconds(0);
      setIsPlaying(true);
      setHasClickedVideoOverlay(true);
      setTimeout(() => {
        videoRef.current?.play();
      }, 100);
    } catch (error) {
      formatAndShowError(error);
    }
  }, [
    webinar.id,
    cookies._hermes_web_visitor,
    attendee?.id,
    createOrUpdateWebinarView,
    formatAndShowError,
  ]);

  const buildUrl = useMemo(() => {
    const baseUrl = `https://${
      process.env.NEXT_PUBLIC_HMS_SUBDOMAIN
    }.app.100ms.live/meeting/${roomCodeOverride || webinar.hmsViewerRoomCode}`;
    const params = new URLSearchParams();

    if (attendee && currentUser) {
      params.append('userId', attendee.id);
      params.append(
        'name',
        currentUser?.firstName + ' ' + currentUser?.lastName
      );
    }

    const queryString = params.toString();
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
  }, [attendee, roomCodeOverride, webinar.hmsViewerRoomCode, currentUser]);

  const formattedTimes = useMemo(() => {
    const startTime = getLocalDate(
      webinar.startedBroadcastingAt || webinar.startTime,
      webinar.timezone
    ).local();
    const endTime = getLocalDate(
      webinar.stoppedBroadcastingAt || webinar.endTime,
      webinar.timezone
    ).local();
    const { hours: durationHours, minutes: durationMinutes } =
      getWebinarDuration(
        webinar.startedBroadcastingAt || webinar.startTime,
        webinar.stoppedBroadcastingAt || webinar.endTime,
        webinar.timezone
      );

    return {
      durationHours,
      durationMinutes,
      endTime,
      startTime,
    };
  }, [
    webinar.startTime,
    webinar.endTime,
    webinar.timezone,
    webinar.startedBroadcastingAt,
    webinar.stoppedBroadcastingAt,
  ]);

  // A room code means it's a URL shared to a speaker or moderator.
  // People with this link aren't expected to login to enter the webinar room.
  const canEnterRoomBecauseOfRoomcode =
    roomCodeOverride &&
    (webinar.state === 'scheduled' ||
      webinar.state === 'draft' ||
      webinar.state === 'live');

  const webinarIsLive =
    (webinar.state === 'live' &&
      webinar.type !== 'recording_only' &&
      isLoggedIn &&
      attendee) ||
    canEnterRoomBecauseOfRoomcode;

  const recordingIsAvailable =
    webinar.state === 'completed' && webinar.publishedRecordingUrl;

  const canWatchVideo = webinar.recordingNeedsLogin ? isLoggedIn : true;

  const isVimeoUrl = webinar.publishedRecordingUrl?.includes('vimeo.com');
  const isCloudinaryUrl =
    webinar.publishedRecordingUrl?.includes('res.cloudinary.com');

  const formattedSocialUrl = formatVideoUrl(
    webinar.publishedRecordingUrl || ''
  );

  const renderVideo = () => {
    const videoElement =
      isVimeoUrl && formattedSocialUrl ? (
        <iframe
          className="aspect-video h-full w-full bg-black"
          src={formattedSocialUrl}
          title="webinar-video"
        />
      ) : isCloudinaryUrl ? (
        <div className="flex h-full w-full justify-center bg-black">
          <div className="h-[650px] w-[1155px]">
            <CldVideoPlayer
              height={1080}
              src={webinar.publishedRecordingUrl || ''}
              width={1920}
            />
          </div>
        </div>
      ) : (
        <video
          ref={videoRef}
          controls
          className="h-full w-full bg-black"
          poster={webinar.posterImageUrl || ''}
          src={webinar.publishedRecordingUrl || ''}
        >
          Your browser does not support the video tag.
        </video>
      );
    return (
      <>
        {videoElement}

        {!hasClickedVideoOverlay && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/50">
            <button
              aria-label="Watch recording"
              className="group flex h-20 w-20 items-center justify-center rounded-full bg-white/20 transition-all hover:bg-white/30"
              onClick={handleWatchRecording}
            >
              <svg
                className="h-12 w-12 text-white transition-transform group-hover:scale-110"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            </button>
          </div>
        )}
      </>
    );
  };

  const renderBanner = () => {
    if (webinarIsLive) {
      return (
        <div ref={videoContainerRef} className="relative h-[650px] w-full">
          <iframe
            allow="camera *;microphone *;display-capture *"
            className="h-full w-full border-0"
            src={buildUrl}
            title={webinar.title || 'Webinar'}
          ></iframe>
          <button
            aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
            className="absolute right-4 top-4 hidden rounded-full bg-black/50 p-2 text-white hover:bg-black/70 sm:block"
            onClick={toggleFullscreen}
          >
            {isFullscreen ? (
              <ArrowsPointingInIcon className="h-5 w-5" />
            ) : (
              <ArrowsPointingOutIcon className="h-5 w-5" />
            )}
          </button>
        </div>
      );
    }

    if (recordingIsAvailable && canWatchVideo) {
      return (
        <div ref={videoContainerRef} className="relative h-[650px] w-full">
          {renderVideo()}
          <button
            aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
            className={clsx(
              'absolute right-4 top-4 hidden rounded-full bg-black/50 p-2 text-white hover:bg-black/70',
              isCloudinaryUrl ? 'sm:hidden' : 'sm:block'
            )}
            onClick={toggleFullscreen}
          >
            {isFullscreen ? (
              <ArrowsPointingInIcon className="h-5 w-5" />
            ) : (
              <ArrowsPointingOutIcon className="h-5 w-5" />
            )}
          </button>
        </div>
      );
    }

    return (
      <WebinarPlaceholderPanel
        attendee={attendee}
        handleRegister={handleRegister}
        isLoggedIn={isLoggedIn}
        timeRemaining={timeRemaining}
        webinar={webinar}
      />
    );
  };

  return (
    <div className="flex flex-col">
      {renderBanner()}
      <div className="mx-auto w-full max-w-screen-xl px-4 py-8 sm:px-6">
        <div className="mb-4 mt-2 flex items-center gap-2 text-gray-500">
          {webinar.state === 'live' && (
            <Badge state="positive" title="Live" variant="primary" />
          )}
          {webinar.state === 'completed' && (
            <Badge state="neutral" title="Ended" variant="secondary" />
          )}
          <Typography className="flex items-center" variant="body-small">
            {formattedTimes.startTime.format('DD/MM/YYYY')}
          </Typography>
          <Typography className="flex items-center" variant="body-small">
            {formattedTimes.startTime.format('h:mma')} -{' '}
            {formattedTimes.endTime.format('h:mma')}{' '}
            {formatDuration(
              formattedTimes.durationHours,
              formattedTimes.durationMinutes
            )}
          </Typography>
        </div>
        <BlockHeading>{webinar.title}</BlockHeading>
        {!!webinar.transcriptSummary && webinar.showTranscriptSummaryOnHub ? (
          <RenderBlockNote
            fullWidth
            content={
              (webinar.transcriptSummary as { content: Block[] }).content
            }
          />
        ) : (
          !!webinar.summary && (
            <RenderBlockNote
              fullWidth
              content={(webinar.summary as { content: Block[] }).content}
            />
          )
        )}
        <WebinarDocuments canDownload documents={webinar.documents} />
      </div>
      {webinar.allowPreWebinarComments ? (
        <WebinarQuestions
          client={client}
          hasStarted={['live', 'completed'].includes(webinar.state || '')}
          isLoggedIn={isLoggedIn}
          questions={attendee?.questions}
          webinar={webinar}
        />
      ) : null}
    </div>
  );
};

const getWebinarStatusText = (
  webinar: WebinarViewerProps['webinar']
): string => {
  if (webinar.type === 'recording_only') {
    return webinar.publishedRecordingUrl
      ? 'Recording available'
      : 'Recording coming soon';
  }

  if (webinar.state === 'live') {
    return 'Webinar is live now';
  }

  const now = getLocalDate(dayjs().toISOString(), webinar.timezone);
  const startTime = getLocalDate(webinar.startTime, webinar.timezone);

  return now.isAfter(startTime)
    ? 'Webinar will start soon'
    : 'Webinar starting in';
};

const WebinarPlaceholderPanel: React.FC<{
  attendee?: WebinarViewerProps['attendee'];
  handleRegister: () => Promise<void>;
  isLoggedIn: boolean;
  timeRemaining: ReturnType<typeof getTimeRemaining>;
  webinar: WebinarViewerProps['webinar'];
}> = ({ attendee, handleRegister, isLoggedIn, timeRemaining, webinar }) => {
  const router = useRouter();

  if (webinar.state === 'completed' && webinar.type !== 'recording_only') {
    const finishedTime = getLocalDate(
      webinar.stoppedBroadcastingAt || webinar.endTime,
      webinar.timezone
    );

    return (
      <WebinarBackgroundContainer webinar={webinar}>
        <div className="z-10 flex flex-col items-center justify-center rounded-lg bg-company-primary px-16 py-10">
          <Typography
            className="text-company-primary-text"
            variant="display-medium"
          >
            {webinar.publishedRecordingUrl
              ? 'Watch recording'
              : 'Webinar finished'}
          </Typography>
          {webinar.publishedRecordingUrl &&
          webinar.recordingNeedsLogin &&
          !isLoggedIn ? (
            <>
              <Typography
                className="mt-4 text-company-primary-text"
                variant="body-large"
              >
                To view the recording of this webinar please either log in or
                sign up.
              </Typography>
              <div className="mt-10 flex space-x-4">
                <HubButton
                  isAccent
                  label="Log in"
                  url="/auth/signin"
                  onClick={(e) => {
                    e.preventDefault();
                    setReturnToCookie(router.asPath);
                    router.push('/auth/signin');
                  }}
                />
                <HubButton
                  isAccent
                  label="Sign up"
                  url="/auth/signup"
                  onClick={(e) => {
                    e.preventDefault();
                    setReturnToCookie(router.asPath);
                    router.push('/auth/signup');
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <Typography className="mt-4" variant="body-large">
                This webinar ended {finishedTime.fromNow()}.
              </Typography>
              <Typography className="mt-2" variant="body-large">
                Recording coming soon
              </Typography>
            </>
          )}
        </div>
      </WebinarBackgroundContainer>
    );
  }

  const remainingTime =
    webinar.state === 'live'
      ? getLocalDate(webinar.endTime, webinar.timezone).diff(
          getLocalDate(dayjs().toISOString(), webinar.timezone),
          'second'
        )
      : timeRemaining.total / 1000;

  return (
    <WebinarBackgroundContainer webinar={webinar}>
      <div className="z-10 flex flex-col items-center justify-center space-y-16 rounded-none bg-company-primary px-20 py-10 sm:rounded-lg">
        <div className="space-y-8">
          <Typography
            className="whitespace-normal text-center font-normal"
            variant="display-medium"
          >
            {webinar.title}
          </Typography>
          <div className="flex flex-col items-center justify-center">
            <Typography className="font-normal" variant="display-small">
              {getWebinarStatusText(webinar)}
            </Typography>

            {webinar.type !== 'recording_only' && (
              <>
                <Typography className="font-normal" variant="body-large">
                  {webinar.state === 'scheduled' &&
                    `${formatTimeRemaining(remainingTime, 'Hold tight!')}`}
                  {webinar.state === 'live' &&
                    `${formatTimeRemaining(remainingTime)} remaining`}
                </Typography>

                <div className="mt-4 flex flex-col items-center gap-2 sm:flex-row">
                  <Typography
                    className="flex items-center"
                    variant="body-regular"
                  >
                    {formatWebinarTime(
                      webinar.startTime,
                      webinar.timezone,
                      'DD/MM/YYYY'
                    )}
                  </Typography>
                  <Typography
                    className="flex items-center"
                    variant="body-regular"
                  >
                    {formatWebinarTime(
                      webinar.startTime,
                      webinar.timezone,
                      'h:mma'
                    )}{' '}
                    -{' '}
                    {formatWebinarTime(
                      webinar.endTime,
                      webinar.timezone,
                      'h:mma'
                    )}{' '}
                    {formatDuration(
                      getWebinarDuration(
                        webinar.startTime,
                        webinar.endTime,
                        webinar.timezone
                      ).hours,
                      getWebinarDuration(
                        webinar.startTime,
                        webinar.endTime,
                        webinar.timezone
                      ).minutes
                    )}
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>

        {isLoggedIn ? (
          webinar.type === 'recording_only' ? (
            webinar.publishedRecordingUrl ? (
              !attendee?.viewedRecordingAt && (
                <HubButton
                  isAccent
                  label="Watch recording"
                  onClick={handleRegister}
                />
              )
            ) : (
              <Typography className="mt-4" variant="body-large">
                This webinar will be recorded privately.{' '}
                {webinar.allowPreWebinarComments
                  ? "Submit any questions you'd like answered in the recording below."
                  : ''}
              </Typography>
            )
          ) : attendee ? (
            <div className="flex flex-col items-center gap-3">
              <div className="mb-4 flex items-center">
                <div className="mr-2 rounded-full bg-white p-1">
                  <CheckIcon className="h-5 w-5 text-company-primary" />
                </div>
                <Typography variant="body-large">
                  You are registered to attend
                </Typography>
              </div>
              <AddToCalendarButton
                description={`Join the webinar at: ${window.location.origin}${router.asPath}`}
                endDate={formatWebinarTime(
                  webinar.endTime,
                  webinar.timezone,
                  'YYYY-MM-DD'
                )}
                endTime={formatWebinarTime(
                  webinar.endTime,
                  webinar.timezone,
                  'HH:mm'
                )}
                location="Online"
                name={webinar.title || 'Webinar'}
                options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo']}
                startDate={formatWebinarTime(
                  webinar.startTime,
                  webinar.timezone,
                  'YYYY-MM-DD'
                )}
                startTime={formatWebinarTime(
                  webinar.startTime,
                  webinar.timezone,
                  'HH:mm'
                )}
                styleDark="--font: inherit;"
                styleLight=" --font: inherit;"
                timeZone={webinar.timezone || 'UTC'}
              />
            </div>
          ) : (
            <div className="flex justify-center">
              <HubButton
                isAccent
                label={
                  webinar.state === 'live'
                    ? 'Join webinar'
                    : 'Register to attend'
                }
                onClick={handleRegister}
              />
            </div>
          )
        ) : (
          <div className="flex flex-col items-center gap-5">
            <Typography className="text-center" variant="body-large">
              Log in or sign up to view this webinar
            </Typography>
            <div className="flex space-x-4">
              <HubButton
                isAccent
                label="Log in"
                url="/auth/signin"
                onClick={(e) => {
                  e.preventDefault();
                  setReturnToCookie(router.asPath);
                  router.push('/auth/signin');
                }}
              />
              <HubButton
                isAccent
                label="Sign up"
                url="/auth/signup"
                onClick={(e) => {
                  e.preventDefault();
                  setReturnToCookie(router.asPath);
                  router.push('/auth/signup');
                }}
              />
            </div>
          </div>
        )}
      </div>
    </WebinarBackgroundContainer>
  );
};
